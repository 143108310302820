define("discourse/plugins/poll/discourse/components/poll-breakdown-option", ["exports", "@ember/component", "@ember/object/computed", "@ember/template", "@ember-decorators/component", "discourse/lib/computed", "discourse-common/utils/decorators", "discourse-i18n", "discourse/plugins/poll/lib/chart-colors", "@ember/template-factory"], function (_exports, _component, _computed, _template, _component2, _computed2, _decorators, _discourseI18n, _chartColors, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li
    class="poll-breakdown-option"
    style={{this.colorBackgroundStyle}}
    {{on "mouseover" @onMouseOver}}
    {{on "mouseout" @onMouseOut}}
    role="button"
  >
    <span
      class="poll-breakdown-option-color"
      style={{this.colorPreviewStyle}}
    ></span>
  
    <span class="poll-breakdown-option-count">
      {{#if this.showPercentage}}
        {{this.percent}}%
      {{else}}
        {{@option.votes}}
      {{/if}}
    </span>
    <span class="poll-breakdown-option-text">{{html-safe @option.html}}</span>
  </li>
  */
  {
    "id": "NTR1m1S2",
    "block": "[[[11,\"li\"],[24,0,\"poll-breakdown-option\"],[16,5,[30,0,[\"colorBackgroundStyle\"]]],[24,\"role\",\"button\"],[4,[38,0],[\"mouseover\",[30,1]],null],[4,[38,0],[\"mouseout\",[30,2]],null],[12],[1,\"\\n  \"],[10,1],[14,0,\"poll-breakdown-option-color\"],[15,5,[30,0,[\"colorPreviewStyle\"]]],[12],[13],[1,\"\\n\\n  \"],[10,1],[14,0,\"poll-breakdown-option-count\"],[12],[1,\"\\n\"],[41,[30,0,[\"showPercentage\"]],[[[1,\"      \"],[1,[30,0,[\"percent\"]]],[1,\"%\\n\"]],[]],[[[1,\"      \"],[1,[30,3,[\"votes\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"poll-breakdown-option-text\"],[12],[1,[28,[35,2],[[30,3,[\"html\"]]],null]],[13],[1,\"\\n\"],[13]],[\"@onMouseOver\",\"@onMouseOut\",\"@option\"],false,[\"on\",\"if\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/poll/discourse/components/poll-breakdown-option.hbs",
    "isStrictMode": false
  });
  let PollBreakdownOption = _exports.default = (_dec = (0, _component2.tagName)(""), _dec2 = (0, _computed2.propertyEqual)("highlightedOption", "index"), _dec3 = (0, _computed.equal)("displayMode", "percentage"), _dec4 = (0, _decorators.default)("option.votes", "totalVotes"), _dec5 = (0, _decorators.default)("optionsCount"), _dec6 = (0, _decorators.default)("highlighted"), _dec7 = (0, _decorators.default)("highlighted", "optionColors", "index"), _dec(_class = (_class2 = class PollBreakdownOption extends _component.default {
    constructor() {
      super(...arguments);
      // Arguments:
      _defineProperty(this, "option", null);
      _defineProperty(this, "index", null);
      _defineProperty(this, "totalVotes", null);
      _defineProperty(this, "optionsCount", null);
      _defineProperty(this, "displayMode", null);
      _defineProperty(this, "highlightedOption", null);
      _defineProperty(this, "onMouseOver", null);
      _defineProperty(this, "onMouseOut", null);
      _initializerDefineProperty(this, "highlighted", _descriptor, this);
      _initializerDefineProperty(this, "showPercentage", _descriptor2, this);
    }
    percent(votes, total) {
      return _discourseI18n.default.toNumber(votes / total * 100.0, {
        precision: 1
      });
    }
    optionColors(optionsCount) {
      return (0, _chartColors.getColors)(optionsCount);
    }
    colorBackgroundStyle(highlighted) {
      if (highlighted) {
        // TODO: Use CSS variables (#10341)
        return (0, _template.htmlSafe)("background: rgba(0, 0, 0, 0.1);");
      }
    }
    colorPreviewStyle(highlighted, optionColors, index) {
      const color = highlighted ? window.Chart.helpers.getHoverColor(optionColors[index]) : optionColors[index];
      return (0, _template.htmlSafe)(`background: ${color};`);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "highlighted", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "showPercentage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "percent", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "percent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "optionColors", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "optionColors"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "colorBackgroundStyle", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "colorBackgroundStyle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "colorPreviewStyle", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "colorPreviewStyle"), _class2.prototype)), _class2)) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PollBreakdownOption);
});